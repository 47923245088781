import React, {FC, useEffect, useState} from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import toast, { Toaster } from 'react-hot-toast';
const Schema = Yup.object().shape({
  name: Yup.string().required('Name field is required'),
  email: Yup.string().required('Email field is required'),
});
export interface IAdd {
  name: string,
  email: string,
  }

const Profile = ({isOpen,data}:any,) => {
  const isOpenstatus=(status:any,data:any) =>{ isOpen(status,data);  }
  

  const [edit, setEditList] = useState<any>([]);

  const [profilpreview, setProfilepreview] = useState<any>(data.pic);
  const [profilpic, setProfilpic] = useState<any>(null);
  const [loading, setLoading] = useState(false)
  let initialValues: IAdd = {
  name: data.first_name,
  email: data.email,
  }

  const Profilehandler = (event: any) => {
    if (event.target.files && event.target.files[0]) {
        let img = URL.createObjectURL(event.target.files[0]);
        setProfilepreview(img);
        setProfilpic(event.target.files[0]);
    }
  };

  const formik = useFormik<IAdd>({
    enableReinitialize:true,
    initialValues,
    validationSchema: Schema,   
    onSubmit: async (Values, {setStatus, setSubmitting}) => {

        let formdata = new FormData;
        formdata.append('member_name',Values.name);
        formdata.append('email_id',Values.email);
        formdata.append('member_profile_pic',profilpic);
        try{
            const URL = `${process.env.REACT_APP_API_URL}/update_member_profile/${data.id}`
            axios({
            method: 'post',
            url: URL,
            withCredentials: false,
            headers: {
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`
            },
            data:formdata
            }).then((response) => {
                if(response.data.status == 200){
                    setLoading(true)
                    // notify('success',response.data.message);
                    isOpenstatus('success',response.data.message)
                            
                }else{
                    setLoading(false)
                    // notify('error',response.data.error_msg.primary_number[0]);
                    isOpenstatus('error',response.data.error_msg.primary_number[0])
                } 
                
                
            });
        }
        catch (error) {
            
                setStatus('Field is required')
                setSubmitting(false)
                setLoading(false)
            }
    },
  })
  

  // const notify = (status:any,data:any) => {

  //   if(status == 'error'){
  //       toast.error(data, {
  //           style: {
  //           borderRadius: '10px',
  //           // border: '1px solid #FFFAEE',
  //           padding: '16px',
  //           color: '#FFFAEE',
  //           background:"#ff4136",
            
  //           },
  //           iconTheme: {
  //           primary: '#FFFAEE',
  //           secondary: '#ff4136',
  //           },
  //       }
  //   );
  //   }else{
  //   toast.success(data, {
  //       style: {
  //       borderRadius: '10px',
  //       // border: '1px solid #FFFAEE',
  //       padding: '16px',
  //       color: '#FFFAEE',
  //       background:"#1C2241",
        
  //       },
  //       iconTheme: {
  //       primary: '#FFFAEE',
  //       secondary: '#1f8f1f',
  //       },
  //   }
  //   );
  //   }
    
  // }
  
  return (
    <>
     {/* <Toaster position="top-right"/> */}
      <div className='modal-body scroll-y'>  
        <div className='modal-header light-color-primary border-0 m-0 p-0'style={{backgroundColor: " "}}> 
          <h2 className=''>Profile Details</h2>
          
        </div>
        <div className="separator border-light-primary my-4"></div>
        <form onSubmit={formik.handleSubmit} noValidate className="form-inline w-100">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main" >
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div id="kt_app_content_container" className="container-xxl">
                <div className="card"> 
                  <div className="">
                    <div className='row'>
                        <div className="col-lg-12">
                          <label className='col-form-label required fw-semibold fs-6'>Name</label>
                          <input type='text' className='form-control form-control-lg form-control-solid'
                            placeholder='Enter Name' {...formik.getFieldProps('name')}
                                                
                            />
                            {formik.touched.name && formik.errors.name && 
                                (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'> 
                                            {formik.errors.name}
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="col-lg-12">
                          <label className='col-form-label required fw-semibold fs-6'>Email ID</label>
                          <input type='text' className='form-control form-control-lg form-control-solid'
                          placeholder='Enter Email ID' {...formik.getFieldProps('email')}
                                   disabled             
                            />
                            {formik.touched.email && formik.errors.email && 
                                (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'> 
                                            {formik.errors.email}
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className='col-lg-12'>
                          <div className='row'>
                            <label className='col-form-label required fw-semibold fs-6'>Profile Picture</label>
                            <div className="">
                              <div className=" shadow image-input" data-kt-image-input="true" >
                                <div className="image-input-wrapper w-125px h-125px">
                                {
                                    profilpreview  ? (<img src={profilpreview} className='image-input-wrapper w-125px h-125px' />):(<img src={toAbsoluteUrl('/images/Team.jpg')} className='image-input-wrapper w-125px h-125px' />)
                                }
                                {/* {profilpreview && <img src={profilpreview} className='image-input-wrapper w-125px h-125px' />} */}
                                </div>
                                <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                  <i className="bi bi-pencil-fill fs-7"></i>
                                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={Profilehandler}/>
                                    <input type="hidden" name="avatar_remove" />
                                </label>
                                <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                  <i className="bi bi-x fs-2"></i>
                                </span>
                                <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"  onClick={() => setProfilepreview(null)} data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                  <i className="bi bi-x fs-2"></i>
                                </span>
                              </div>
                              <div className="form-text pt-2">Allowed file types: png, jpg, jpeg.</div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
    
          <div className='d-flex justify-content-center align-items-center mt-4'>
            <button type="reset" className="btn btn-secondary me-3" data-bs-dismiss="modal" style={{margin:"0px 5px 0px 0px"}}  onClick={()=> isOpenstatus('success','Canceled')}>Cancel</button>
            <button type="submit" className="btn btn-primary">Update</button>
          </div>
        </form >
      </div>
    </>
  )
}

export {Profile}
