import React, {ChangeEvent, useEffect, useState,useRef} from 'react'
import * as Yup from 'yup'
import axios from 'axios';
import {Formik, useFormik, FormikHelpers, FormikProps, Form, Field,FieldProps,} from 'formik'
import { Link } from 'react-router-dom';
import { Edit } from '../../../../app/components/learnings/edit'
import { Delete } from '../../../../app/components/learnings/delete'
import { View } from '../../../../app/components/recent_learnings/view'
import { Announcement_view } from '../../../../app/components/previous_announcement/view'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import toast, { Toaster } from 'react-hot-toast';
const Wiselist: React.FC = () => {


    const isOpenstatus = (data:any) => {
      setOpen(false);
    
    // can get callback data here
    }


  const [selectedID, setSelectedID] = useState(null);
 
  const [open, setOpen] = useState(false);
  const [open_model, setOpenModel] = useState('');
 


  
  const  tempDate = new Date();
  //const  date:any = tempDate.getFullYear() + '-' +'0'+ (tempDate.getMonth()+1) + '-' + tempDate.getDate();
  const month:number=  Number(tempDate.getMonth()+1);
  const value:any = month > 9 ? (month):('0'+month);

  const value2:any = tempDate.getDate() > 9 ? (tempDate.getDate()):('0'+tempDate.getDate());
  
  const  date = tempDate.getFullYear() + '-' + value + '-' + value2; 
  const [visit_id, setVisitId] = useState('');


  const [checkboxes, setCheckboxes] = useState<any>([]);

  const handleSelectAllChange = (e:any) => {
    if (e.target.checked) {
      // Get an array of all checkbox values
      const allValues = checkboxData.map((data) => data.value);
      setCheckboxes(allValues);
    } else {
      setCheckboxes([]);
    }
  };
  const checkboxData = [
    { value: '', label: '' },
    // Add more checkboxes as needed
  ];
  const check1 = [
    { value: 'checkbox2', label: ' ' },
    // Add more checkboxes as needed
  ];
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const handleToggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const [isSortVisible, setIsSortVisible] = useState(false);
  const handleToggleFilter_1 = () => {
    setIsSortVisible(!isSortVisible);
  };
  const [date_picker, set_date_picker] = useState<any>('');
  
  const [courselist, setCourselist] = useState<any>([]);
  const [coursecount, setCourse] = useState<any>([]);
  const [lesson, setLesson] = useState<any>([]);

  // filter
  const [cluster, setCluster] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [course_filter, setCourseFilter] = useState<any>([]);
  const [lesson_filter, setLessonFilter] = useState<any>([]);
  const [view, setView] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL
  const [items, setItems] = useState([]);
  const [likelist, setLikelist] = useState<any>(0);
  const [wiselistcount, setWiselist] = useState<any>(0);
  const [wisecourselist, setWiseCourselist] = useState<any>([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState(""); // State for selected category
  const [selectedCourseId, setSelectedCourseId] = useState("");     // State for selected course
  const [selectedLessonId, setSelectedLessonId] = useState("");     // State for selected lesson
  const [selectedClusterId, setSelectedClusterId] = useState(""); 
  const getAnnouncementList = () => {
    const URL = `${API_URL}/member_announcement_list`;
    axios({
      method: 'get',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
    }).then((res) => {

      setItems(res.data.data);

    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }
  
  const getCourseList = () => {
    const URL = `${API_URL}/member_course_list`;
    axios({
      method: 'put',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
    }).then((res) => {
  
      setCourselist(res.data.data);
         setCourse(res.data.count);
        //  setCourseFilter(res.data.count);
    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }
  const getWiseCourseList = (category_id:any = selectedCategoryId,course_id:any = selectedCourseId, lesson_id:any = setSelectedLessonId,asc_id:any=sortingOptionsasc,desc_id:any=sortingOptionsdesc) => {
    const URL = `${API_URL}/wise_course_list`;
    axios({
      method: 'put',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
      data:{category_id:category_id,course_id:course_id,lesson_id:lesson_id,asc_id:asc_id,desc_id:desc_id}
    }).then((res) => {
  
      setWiseCourselist(res.data.data);
        //  setCourseFilter(res.data.count);
    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }
  const getLessonList = () => {
    const URL = `${API_URL}/lesson_list`;
    axios({
      method: 'put',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
    }).then((res) => {
  
      setLesson(res.data.data);
    
      setLessonFilter(res.data.data);
    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }

  const getClusterList = () => {
    const URL = `${API_URL}/member_cluster_list`;
      axios({
      method: 'get',
      url: URL,
      withCredentials: false,
      headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
      }).then((res) => {
        let chaplist:any = [];
        let it = res.data.data;
        it?.map((cluster:any,i:any)=>{
        chaplist.push({
        
        })
        })
        setCluster(res.data.data);
      }).catch(error => {
         console.error('Error fetching cluster list:', error);
      });
   }
   const getCategoryList = () => {
    const URL = `${API_URL}/member_category_list`;
    axios({
      method: 'get',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
    }).then((res) => {
  
      let chaplist:any = [];
      let it = res.data.data;
  
      it?.map((chapter:any,i:any)=>{       
       
      })
      setCategory(res.data.data);
    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }

  const viewCounts = (course_id:any,lesson_id:any,course_view_id:any)=>{
    if(view == 1){
      setView(0)
    }else{
      setView(1)
    }
    const URL = `${process.env.REACT_APP_API_URL}/add_view_course`
        axios({
        method: 'post',
        url: URL,
        
        headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
        data:{course_id:course_id,lesson_id:lesson_id,course_view_id:course_view_id}

        }).then((response) => {

          if(response.data.status == 200){
          
          }else{
        
          }
          
      });
  }
  const AddWiselist = (course_id:any,lesson_id:any,wiselist_id:any)=>{
 
    if(wiselist_id){
    
      setWiselist(wiselistcount-1);

    }else{
    
      setWiselist(wiselistcount+1);
    };

    const URL = `${process.env.REACT_APP_API_URL}/add_wise_list`
    axios({
    method: 'post',
    url: URL,
    
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
    },
    data:{course_id:course_id,lesson_id:lesson_id,wiselist_id:wiselist_id}

    }).then((response) => {

        if(response.data.status == 200){
          
            notify('success',response.data.message);
            getCourseList();
        }else{
       
            notify('error',response.data.message);
        }
        
    });
  }
  const notify = (status:any,data:any) => {
  
    if(status == 'error'){
          toast.error(data, {
            style: {
              borderRadius: '10px',
              // border: '1px solid #FFFAEE',
              padding: '16px',
              color: '#FFFAEE',
              background:"#057091",
              
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#bf192a',
            },
          }
    );
    }else{
      toast.success(data, {
        style: {
          borderRadius: '10px',
          // border: '1px solid #FFFAEE',
          padding: '16px',
          color: '#ffc700 ',
          background:"#1C2241",
          
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#ffc700 ',
        },
      }
      );
    }
    
  } 
  const getWiseList = () => {
    const URL = `${API_URL}/wise_list`;
    axios({
      method: 'get',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
    }).then((res) => {
  
      
         setWiselist(res.data.count)
    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }

  const getLikeList = () => {
    const URL = `${API_URL}/like_list`;
    axios({
      method: 'get',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
    }).then((res) => {
  
  
         setLikelist(res.data.count)
    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }
  const LikeStatus = (l_course_id:any,l_lesson_id:any,like_course_id:any)=>{


    if(like_course_id){
    
      setLikelist(likelist-1);

    }else{
    
      setLikelist(likelist+1);
    };

    const URL = `${process.env.REACT_APP_API_URL}/add_like_list`
      axios({
      method: 'post',
      url: URL,
      
      headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
      data:{course_id:l_course_id,lesson_id:l_lesson_id,like_course_id:like_course_id}

      }).then((response) => {

        if(response.data.status == 200){
          
            notify('success',response.data.message);
            
            getCourseList();
         
        }else{
       
            notify('error',response.data.message);
        }
        
      });
  }

  const handleCategoryChange = (e:any) => {
    setSelectedCategoryId(e.target.value);
    setSelectedCourseId(""); // Reset course selection
    setSelectedClusterId(""); // Reset cluster selection
    // Additional logic to filter courses based on selected category
  };
  
  // Handle course change
  const handleCourseChange = (e:any) => {
    setSelectedCourseId(e.target.value);
    setSelectedClusterId(""); // Reset cluster selection
    // Additional logic to filter clusters based on selected course
  };
  
  // Handle lesson change
  const handleLessonChange = (e:any) => {
    setSelectedLessonId(e.target.value);
  };
  // const handleCategoryChange = (e:any) => {
  //   setSelectedCategoryId(e.target.value);
  //   setSelectedCourseId(""); // Reset course selection
  //   setSelectedClusterId(""); // Reset cluster selection
  //   // Additional logic to filter courses based on selected category
  // };
  
  // // Handle course change
  // const handleCourseChange = (e:any) => {
  //   setSelectedCourseId(e.target.value);
  //   setSelectedClusterId(""); // Reset cluster selection
  //   // Additional logic to filter clusters based on selected course
  // };
  // Handle cluster change
  const handleClusterChange = (e:any) => {
    setSelectedClusterId(e.target.value);
  };
  const [sortingOptionsasc, setSortingOptionsasc] = useState<any>(
    'asc_id'
 
  );
  const [sortingOptionsdesc, setSortingOptionsdesc] = useState<any>(
   
    'desc_id'
  );
  useEffect(()=>{
    getCourseList();
    getLessonList();
    getClusterList();
    getCategoryList();
    getLikeList();
    getWiseList();
    getWiseCourseList();
  },[])
  function getBackgroundImage(lesson: any): string {
    if (lesson.thumbnail_image) {
      return lesson.thumbnail_image; // Use lesson.thumbnail_image if available
    } else if (lesson.url_thumbnail_image) {
      // Construct YouTube thumbnail URL using lesson.url_thumbnail_image
      const videoId = getYouTubeVideoId(lesson.url_thumbnail_image);
      return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    } else {
      return ''; // Default empty string or fallback image URL
    }
  }
  
  // Function to extract YouTube video ID from YouTube video URL
  function getYouTubeVideoId(url:any) {
    // Regular expressions to match various YouTube URL formats
    const regexList = [
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=))([^&\n\s]+)/,
      /youtu\.be\/([^&\n\s]+)/
    ];
  
    // Loop through regex patterns to find a match
    for (const regex of regexList) {
      const match = url.match(regex);
      if (match) {
        return match[1]; // Return the video ID
      }
    }
  
    return ''; // Default empty string if no match is found
  }
  return(
    <>
          {/* <div className="app-main flex-column flex-row-fluid" id="kt_app_main" >
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div id="kt_app_content_container" className="container-xxl"> */}
              <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                  <div className='col-12'> 
                  <div className="card px-4 pb-4"> 
                    <div className="card-header border-0 pt-0 px-2">
                      <div className="card-title">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap">
                          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0">Wishlist</h1>
                          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                          <li className="breadcrumb-item">
                              <Link to="/dashboard" className="pe-2">Home</Link>
                              <i className="fa-solid fa-angles-right"></i>
                          </li>
                          <li className="breadcrumb-item">Course</li>
                        </ul>
                        </div>
                      </div>
                      <div className="card-toolbar">
                      {/* <input className="form-check-input pt-2 checkme h-25px w-25px "  onChange={handleSelectAllChange} name="g" id="b3" type="checkbox" value="1"/>
                      <label className="ms-2 text-dark fw-bold d-block fs-6 me-3">Top Courses</label> */}
                      <p className="btn bg-hover-primary text-hover-white me-3 my-1 btn-sm cursor-pointer" data-kt-menu-trigger="hover" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                            <i className="fa-solid fa-arrow-down-short-wide fs-4 text-primary"></i>
                          </p>
                          <div className="menu menu-sub menu-sub-dropdown w-250px w-md-200px menu-state-bg-light-primary" data-kt-menu="true" data-popper-placement="bottom-end">
                            <div className="px-3 py-3">
                              <div className="menu-item">
                                <p className="menu-link px-5 cursor-pointer" onClick={() => getWiseCourseList(selectedCategoryId, selectedCourseId, setSelectedLessonId, 'asc_id')}>Ascending</p>
                              </div>
                              <div className="menu-item">
                                <p className="menu-link px-5 cursor-pointer" onClick={() => getWiseCourseList(selectedCategoryId, selectedCourseId, setSelectedLessonId, 'desc_id')}>Descending</p> 
                              </div>
                            </div>
                          </div>
                        {/* <p className="cursor-ponter btn bg-hover-primary text-hover-white me-3 my-1 btn-sm" onClick={handleToggleFilter_1} id="tog_filter">
                            <span><i className="fa-solid fa-arrow-up-short-wide fs-3 text-primary"></i></span>
                        </a> */}
                        <p className="btn bg-hover-primary text-hover-white me-3 my-1 btn-sm cursor-pointer" onClick={handleToggleFilter} id="tog_filter">
                            <span><i className="fa fa-filter fs-3 text-primary"></i></span>
                        </p>
                      </div>
                      </div> 
                      <div className="my_filter" style={{ display: isFilterVisible ? 'block' : 'none' }}>
                      <div className='row mt-2 px-2'>                      
                          <div className="col-lg-3">
                            <label className="form-label fw-bold">Category</label>
                            <div>
                                <select className="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_637dc7679e179" data-allow-clear="true"  onChange={handleCategoryChange} value={selectedCategoryId}>
                                  <option value="">-Select category-</option>
                                    {
                                        category?.map((it:any)=>{
                                            return <option key={it.ca_id}  value={it.ca_id}>{it.category_name}</option>
                                        })
                                    }  
                                </select>
                            </div> 
                          </div>
                          <div className="col-lg-3">
                            <label className="form-label fw-bold">Course</label>
                            <div>
                                <select className="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_637dc7679e179" data-allow-clear="true"  onChange={handleCourseChange} value={selectedCourseId}>
                                  <option value="">-Select Course-</option>
                                    {
                                        courselist?.filter((course:any) => course.ca_id === selectedCategoryId).map((it:any)=>{
                                            return <option key={it.co_id} value={it.co_id}>{it.course_name}</option>
                                        })
                                    } 
                                </select>
                            </div> 
                          </div>
                          <div className="col-lg-3">
                            <label className="form-label fw-bold">Lesson</label>
                            <div>
                                <select className="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_637dc7679e179" data-allow-clear="true"  onChange={handleLessonChange} value={selectedLessonId}>
                                    
                                <option value="">-Select Lesson-</option>
                                    {
                                        lesson_filter?.filter((lesson:any) => lesson.co_id === selectedCourseId).map((it:any)=>{
                                            return <option key={it.le_id} value={it.le_id}>{it.lesson_name}</option>
                                        })
                                    } 
                                </select>
                            </div> 
                          </div>
                          <div className="col-lg-3">
                            <label className="form-label fw-bold">Cluster</label>
                            <div>
                                <select className="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_637dc7679e179" data-allow-clear="true"  onChange={handleClusterChange} value={selectedClusterId}>
                                   
                                <option value="">-Select Cluster-</option>
                                    {
                                       cluster?.map((it:any,i:any)=>{
                                            return <option key={i} value={it.cluster_id}>{it.cluster_name}</option>
                                        })
                                    } 
                                </select>
                            </div>   
                          </div>
                            {/* <div className="col-lg-3">
                              <label className='form-label fw-bold'>Select Date</label>
                              <select name="date_picker" id="date_picker" data-control="select2" className="form-select form-select-solid" onChange={(e)=>set_date_picker(e.target.value)}>
                                <option value="all">All</option>
                                <option value="today">Today</option>
                                <option value="this_week">This Week</option>
                                <option value="this_year">This Year</option>
                                <option value="date_range">Date Range</option>
                              </select>
                            </div> */}
                            {
                              date_picker === 'date_range' ? (
                                <>
                                <div className="col-lg-3">
                                  <label className='fs-6 fw-semibold'>From date</label>
                                  <input type='date' className='form-control form-control-lg form-control-solid' name='wedd_date' placeholder='Wedding Date' />
                                </div>
                                <div className="col-lg-3">
                                  <label className='fs-6 fw-semibold'>End date</label>
                                  <input type='date' className='form-control form-control-lg form-control-solid' name='wedd_date' placeholder='Wedding Date' />
                                </div></>
                              ):('')
                            } 
                          <div className="col-lg-3">
                          <button type="button" className="btn btn-primary btn-sm mt-8"onClick={()=>{getWiseCourseList()}}>Go</button>
                          </div>
                        </div>
                    </div>
                   
                      <div className="pt-2 separator border-primary">
                      </div>
                      <div className='row pt-4'>
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap">
                          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0 py-4">All Courses</h1>
                          <div className='row'>
                       
                          { wisecourselist?.filter((f:any)=>{return f.lesson_count >0 }).map((lesson:any,i:any)=>{
                                  return  <div className='col-lg-3 pb-4' key={i}>
                                <div className="card bg-light-primary shadow hoverabl">
                                  <div className="card-body">
                                    <div className='d-flex justify-content-center align-items-center'>
                                    <Link to={`/course/${lesson.course_id}`} key={lesson.course_id} onClick={() => {viewCounts(lesson.course_id,lesson.lesson_id,lesson.course_view_id) }}  style={{
                                       backgroundImage: `url('${getBackgroundImage(lesson)}')`,
                                    }} className="d-block bgi-no-repeat bgi-size-cover bgi-position-center rounded position-relative h-150px w-250px me-1"
                                        >
                                      <img src="images/play_thumb_1.png" width={25}  className="position-absolute top-50 start-50 translate-middle" alt=""/>
                                      </Link>
                                    </div>
                                      <div className='d-block align-items-center'>
                                        <label className="pt-2 text-center fw-bold text-dark fs-3 d-block mb-3">{lesson.course_name}</label>
                      
                                        {lesson.lesson_list && lesson.lesson_list.length > 0 &&
                                            <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                                {lesson.lesson_list[0].lesson_name}
                                            </label>
                                        }
                                        <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                          <span className="pt-2 badge badge-success text-black fs-7">{lesson.lesson_count} Lessons</span>
                                        </label>
                                        <div className='d-block text-center'>
                                        {
                                        lesson.course_view_id > 0?(<i className="me-6 fa-solid fa-rectangle-list fs-2" style={{color: "red"}} title='Entroll'></i>):('')
                                        }  
                                        {

                                        <i className={
                                          
                                          lesson.event_like_status == 1  ? (' me-6   fs-2 fa-heart fa-solid shadow-txt'):('me-6   fs-2 fa-heart fa-regular')} 
                                          onClick={() => {LikeStatus(lesson.course_id,'',lesson.liked_course_id) }} 
                                          title='Liked' style={{color: "red"}}></i>

                                        }

                                       {
                                          lesson.event_wise_status == 1 ? (
                                              <i 
                                                  className="fa-solid fa-circle-check fs-2"
                                                  title="Remove Wishlist"
                                                  style={{
                                                      color: "red",
                                                      borderRadius: "50%", 
                                                      transition: "color 0.3s", 
                                                  }}
                                                  onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                              ></i>
                                          ) : (
                                              <i 
                                                  className="fa-solid fa-check fs-2"
                                                  onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                                  title="Wishlist"
                                                  style={{
                                                      color: "red",
                                                      borderRadius: "50%", 
                                                      transition: "color 0.3s",
                                                  }}
                                                
                                              ></i>
                                          )
                                        }
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                             })}
                          </div>
                          
                      </div>
                      </div>
                  </div>
              </div>
            </div>  
          {/* </div> */}
       {open ?(
          <div className= {open ? "modal fade show bg-black bg-opacity-50 d-block":"modal fade show bg-black bg-opacity-50 d-none"} id='kt_modal_add_company' tabIndex={-1} aria-hidden={true}>
           
           
            {
              open_model == 'view' ? (<>
                <div className='modal-dialog modal-md mt-5'>
                  <div className='modal-content rounded' style={{backgroundColor: "#ffffff"}}>
                    <View isOpen={isOpenstatus} />
                  </div>
                </div>
                </>):(<></>)
            }
             {
              open_model == 'annoucement_view' ? (<>
                <div className='modal-dialog modal-md mt-5'>
                  <div className='modal-content rounded' style={{backgroundColor: "#ffffff"}}>
                    <Announcement_view isOpen={isOpenstatus} />
                  </div>
                </div>
                </>):(<></>)
            }
            
          </div>
        ):(
          <></>
        )
      }
             
    </>
  
  )
}

export {Wiselist}



