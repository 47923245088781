import axios from 'axios';
// import { KTSVG } from '../../../../_metronic/helpers';
// import swal from 'sweetalert';
// import { Swal2 } from '../../../../../../swal2/swal2';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'


const  Announcement_view = ({isOpen,id}:any) => {

  const isOpenstatus=(status:any,data:any) =>{ isOpen(status,data);  }
    
  return (
    <>

      <div className='modal-body scroll-y'>  
        <div className='modal-header light-color-primary border-0 m-0 p-0'style={{backgroundColor: " "}}> 
          <h2 className=''>View Announcement Details</h2>
        
        {/* <div className="card-toolbar">
          <div className="d-flex justify-content-end">
                <span className="badge badge-warning text-dark fs-6">Upcoming</span>
                </div>
          </div> */}
          </div>
        <div className="separator border-light-primary my-4"></div>
        <div className='row'>
            <div className='col-lg-12'>
            {/* <div className='row'>
            <div className='col-lg-12'>
            <div className='d-flex align-items-center'>
                  <div className='symbol symbol-125px me-5'>
                    <img src={toAbsoluteUrl('/images/epc_youtube_1.jpg')} alt=''/>
                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-3'>
                    Agri Learning Video
                    </a>  
                    <label className='fw-bold text-dark fs-7'>
                    Agri Learning Video
                    </label>
                   
                  </div>
                </div>
            </div>
          </div>   */}
          </div>
           
          <div className="col-lg-12">
          <div className='row'>
              {/* <label className="col-lg-3 col-form-label fw-semibold fs-6">Announcement</label>
              <label className="col-lg-1 col-form-label fw-bold fs-5">:</label> */}
              <label className="col-form-label fw-bold fs-5">Tomorrow Uploaded on Agri Industry Video</label>
          </div>
            </div>
            </div>
            
          
        <div className='d-flex justify-content-end align-items-center mt-4'>
          <button type="reset" className="btn btn-secondary me-3" data-bs-dismiss="modal" style={{margin:"0px 5px 0px 0px"}}  onClick={()=> isOpenstatus('success','')}>Cancel</button>
          
        </div>
      
      </div>
    </>
  )
}

export {Announcement_view}