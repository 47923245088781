import React, {FC, useEffect, useState} from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup'
// import toast, { Toaster } from 'react-hot-toast';
const Schema = Yup.object().shape({
  current_password: Yup.string().required('Current Password field is required'),
  confirm_password: Yup.string().required('Password field is required'),
});

export interface IAdd {
  current_password: string,
  new_password: string,
confirm_password: string,
  }
const Edit = ({isOpen,data}:any,) => {
  

// Current Password Condition Start
  const [cur_inputType, setCurInputType] = useState('password')
  const cur_toggleInput = ()=>{
    setCurInputType(cur_inputType === 'password' ? 'text': 'password')
    }
  const [cur_pwd_opn, setcur_pwd_opn] = useState<any>(false);
  const [cur_pwd_cls, setcur_pwd_cls] = useState<any>(true);
  const handleCurEyeOpen = () => {
    cur_toggleInput();
      setcur_pwd_opn(true);
      setcur_pwd_cls(false);
    };
  const handleCurEyeClose = () => {
      cur_toggleInput();
      setcur_pwd_opn(false);
      setcur_pwd_cls(true);
    };
  // Current Password Condition End
  // New Password Condition Start
  const [new_inputType, setNewInputType] = useState('password')
  const new_toggleInput = ()=>{
    setNewInputType(new_inputType === 'password' ? 'text': 'password')
    }
  const [new_pwd_opn, setnew_pwd_opn] = useState<any>(false);
  const [new_pwd_cls, setnew_pwd_cls] = useState<any>(true);
  const handleNewEyeOpen = () => {
    new_toggleInput();
    setnew_pwd_opn(true);
    setnew_pwd_cls(false);
    };
  const handleNewEyeClose = () => {
    new_toggleInput();
    setnew_pwd_opn(false);
    setnew_pwd_cls(true);
    };
  // New Password Condition End
  // Confirm New Password Condition Start
  const [cfm_new_inputType, setCfmNewInputType] = useState('password')
  const cfm_new_toggleInput = ()=>{
    setCfmNewInputType(cfm_new_inputType === 'password' ? 'text': 'password')
    }
  const [cfm_new_pwd_opn, setcfm_new_pwd_opn] = useState<any>(false);
  const [cfm_new_pwd_cls, setcfm_new_pwd_cls] = useState<any>(true);
  const handleCfmNewEyeOpen = () => {
    cfm_new_toggleInput();
    setcfm_new_pwd_opn(true);
    setcfm_new_pwd_cls(false);
    };
  const handleCfmNewEyeClose = () => {
    cfm_new_toggleInput();
    setcfm_new_pwd_opn(false);
    setcfm_new_pwd_cls(true);
    };
  // Confirm New Password Condition End
  const isOpenstatus=(status:any,data:any) =>{ isOpen(status,data);  }
  

  const [edit, setEditList] = useState<any>([]);

 
 
  const [loading, setLoading] = useState(false)
  let initialValues: IAdd = {
    current_password: '',
    new_password: '',
  confirm_password: '',
  }
  

  const formik = useFormik<IAdd>({
    enableReinitialize:true,
    initialValues,
    validationSchema: Schema,   
    onSubmit: async (Values, {setStatus, setSubmitting}) => {

        let formdata = new FormData;
        formdata.append('current_password',Values.current_password);
        formdata.append('new_password',Values.new_password);
        formdata.append('confirm_password',Values.confirm_password);
        try{
            const URL = `${process.env.REACT_APP_API_URL}/change_member_password/${data.id}`
            axios({
            method: 'post',
            url: URL,
            withCredentials: false,
            headers: {
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`
            },
            data:formdata
            }).then((response) => {
                if(response.data.status == 200){
                    setLoading(true)
                    // notify('success',response.data.message);
                    isOpenstatus('success',response.data.message)
                            
                }else{
                    setLoading(false)
                    // notify('error',response.data.message);
                    isOpenstatus('error',response.data.error_msg.primary_number[0])
                } 
                
                
            });
        }
        catch (error) {
            
                setStatus('Field is required')
                setSubmitting(false)
                setLoading(false)
            }
    },
  })
  

  // const notify = (status:any,data:any) => {

  //   if(status == 'error'){
  //       toast.error(data, {
  //           style: {
  //           borderRadius: '10px',
  //           // border: '1px solid #FFFAEE',
  //           padding: '16px',
  //           color: '#FFFAEE',
  //           background:"#ff4136",
            
  //           },
  //           iconTheme: {
  //           primary: '#FFFAEE',
  //           secondary: '#ff4136',
  //           },
  //       }
  //   );
  //   }else{
  //   toast.success(data, {
  //       style: {
  //       borderRadius: '10px',
  //       // border: '1px solid #FFFAEE',
  //       padding: '16px',
  //       color: '#FFFAEE',
  //       background:"#1C2241",
        
  //       },
  //       iconTheme: {
  //       primary: '#FFFAEE',
  //       secondary: '#1f8f1f',
  //       },
  //   }
  //   );
  //   }
    
  // }
useEffect(()=>{

},[])
    
  return (
    <>
    {/* <Toaster position="top-right"/> */}
      <div className='modal-body scroll-y'>  
        <div className='modal-header light-color-primary border-0 m-0 p-0'style={{backgroundColor: " "}}> 
          <h2 className=''>Change Password</h2>
          
        </div>
        <div className="separator border-light-primary my-4"></div>
        <form onSubmit={formik.handleSubmit} noValidate className="form-inline w-100">
          <div className='row'>
            <div className="col-lg-12 position-relative">
              <label className='col-form-label required fw-semibold fs-6'>Current Password</label>
              <input
                type={cur_inputType}
                className='form-control form-control-lg form-control-solid '
                id='cur_pwd' placeholder='Enter Current Password'
                {...formik.getFieldProps('current_password')}
                                                
                />
                {formik.touched.current_password && formik.errors.current_password && 
                    (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'> 
                                {formik.errors.current_password}
                            </div>
                        </div>
                    )}
              {cur_pwd_opn && 
                <p  className="position-absolute translate-middle-y top-75 end-0 me-6 cursor-pointer" onClick={handleCurEyeClose}>
                  <i className="fa-solid fa-eye-slash fs-2 text-hover-primary"></i>
                </p>
              }
              {cur_pwd_cls && 
                <p className="position-absolute translate-middle-y top-75 end-0 me-6 cursor-pointer" onClick={handleCurEyeOpen}>
                <i className="fa-solid fa-eye fs-2 text-hover-primary"></i>
              </p>
              }
            </div>
            <div className="col-lg-12 position-relative">
              <label className='col-form-label required fw-semibold fs-6'>New Password</label>
              <input
                type={new_inputType}
                className='form-control form-control-lg form-control-solid '
                id='new_pwd' placeholder='Enter New Password'
                {...formik.getFieldProps('new_password')}
                                                
                />
                {formik.touched.new_password && formik.errors.new_password && 
                    (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'> 
                                {formik.errors.new_password}
                            </div>
                        </div>
                    )}
              {new_pwd_opn && 
                <p className="position-absolute translate-middle-y top-75 end-0 me-6 cursor-pointer" onClick={handleNewEyeClose}>
                  <i className="fa-solid fa-eye-slash fs-2 text-hover-primary"></i>
                </p>
              }
              {new_pwd_cls && 
                <p className="position-absolute translate-middle-y top-75 end-0 me-6 cursor-pointer" onClick={handleNewEyeOpen}>
                <i className="fa-solid fa-eye fs-2 text-hover-primary"></i>
              </p>
              }
            </div>
            <div className="col-lg-12 position-relative">
              <label className='col-form-label required fw-semibold fs-6'>Confirm New Password</label>
              <input
                type={cfm_new_inputType}
                className='form-control form-control-lg form-control-solid '
                id='cfm_new_pwd' placeholder='Enter Confirm New Password'
                {...formik.getFieldProps('confirm_password')}
                                                
                />
                {formik.touched.confirm_password && formik.errors.confirm_password && 
                    (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'> 
                                {formik.errors.confirm_password}
                            </div>
                        </div>
                    )}
              {cfm_new_pwd_opn && 
                <p className="position-absolute translate-middle-y top-75 end-0 me-6 cursor-pointer" onClick={handleCfmNewEyeClose}>
                  <i className="fa-solid fa-eye-slash fs-2 text-hover-primary"></i>
                </p>
              }
              {cfm_new_pwd_cls && 
                <p className="position-absolute translate-middle-y top-75 end-0 me-6 cursor-pointer" onClick={handleCfmNewEyeOpen}>
                <i className="fa-solid fa-eye fs-2 text-hover-primary"></i>
              </p>
              }
            </div>
          </div>
          <div className='d-flex justify-content-center align-items-center mt-4'>
            <button type="reset" className="btn btn-secondary me-3" data-bs-dismiss="modal" style={{margin:"0px 5px 0px 0px"}}  onClick={()=> isOpenstatus('success','Canceled')}>Cancel</button>
            <button type="submit" className="btn btn-primary" >Update</button>
          </div>
        </form>
      </div>
    </>
  )
}

export {Edit}
