import {Suspense, useEffect} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, Logout, removeAuth, useAuth} from './modules/auth'

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {logout} = useAuth();
  useEffect(()=>{
    if (location.pathname === '/auth/password_change') {
      removeAuth();
      logout()
      navigate('/auth/password_change')

    }
  },[location,navigate])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
