import React, {ChangeEvent, useEffect, useState,useRef} from 'react'
import axios from 'axios';
import {Formik, useFormik, FormikHelpers, FormikProps, Form, Field,FieldProps,} from 'formik'
import {Link, useNavigate,useParams } from 'react-router-dom'
import { View } from '../../../components/recent_learnings/view'
import { Announcement_view } from '../../../components/previous_announcement/view'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import FsLightbox from "fslightbox-react";
import ReactPlayer from 'react-player';
import toast, { Toaster } from 'react-hot-toast';
const Course: React.FC = () => {


  const isOpenstatus = (data:any) => {
    setOpen(false);
   
// can get callback data here
}
const [tick, set_tick] = useState<any>(false);
const [new_tick, setnew_tick] = useState<any>(true);
const handleClose = () => {
  set_tick(true);
  setnew_tick(false);
  };

  const [selectedID, setSelectedID] = useState(null);
  // const handleClick = (md_id:any) => {
  //   setSelectedID(md_id);
  // };
  // const isLoading = useQueryResponseLoading()
  const [open, setOpen] = useState(false);
  const [open_model, setOpenModel] = useState('');
  const [viewdata, setviewData] = useState<any>('');
  const [del_id, setdelId] = useState<any>(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [exports, setExport] = useState([]);
  const [nature, setNatureList] = useState([]);
  const [industry, setindustryList] = useState([]);
  const [staff, setStaffList] = useState([]);
  const [leadstage, setLeadStage] = useState([]);
  const  [leadarchived_id, setLeadarchivedId] = useState<any>()
  const  [leadreturn_id, setLeadreturnId] = useState<any>()
  const [sortpage, setSortPage] = useState(25);
  const [totalPages, setTotalPages] = useState<any>(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [minidash, setMniDash] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const  tempDate = new Date();
  //const  date:any = tempDate.getFullYear() + '-' +'0'+ (tempDate.getMonth()+1) + '-' + tempDate.getDate();
  const month:number=  Number(tempDate.getMonth()+1);
  const value:any = month > 9 ? (month):('0'+month);

  const value2:any = tempDate.getDate() > 9 ? (tempDate.getDate()):('0'+tempDate.getDate());
  
  const  date = tempDate.getFullYear() + '-' + value + '-' + value2; 
  const [leadtype, setLeadType] = useState('Domestic');
  const [from_date, setFromDate] = useState(date);
  const [to_date, setToDate] = useState(date);
  const [upcomming, setUpcomming] = useState('');
  const [visit_id, setVisitId] = useState('');
  const [customer_type, setCustomerType] = useState('');
  const [customer_status, setCustomerStatus] = useState('');
  const [all, setAll] = useState('');
  const [search_username, setSearchUsername] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [dateformat, setDateFormat] = useState('all');
  


  const [checkboxes, setCheckboxes] = useState<any>([]);

  const handleCheckboxChange = (value:any) => {
    if (checkboxes.includes(value)) {
      setCheckboxes(checkboxes.filter((box:any) => box !== value));
    } else {
      setCheckboxes([...checkboxes, value]);
    }
  };

  const handleSelectAllChange = (e:any) => {
    if (e.target.checked) {
      // Get an array of all checkbox values
      const allValues = checkboxData.map((data) => data.value);
      setCheckboxes(allValues);
    } else {
      setCheckboxes([]);
    }
  };
  const checkboxData = [
    { value: '', label: '' },
    // Add more checkboxes as needed
  ];
  const check1 = [
    { value: 'checkbox2', label: ' ' },
    // Add more checkboxes as needed
  ];
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const handleToggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const [isSortVisible, setIsSortVisible] = useState(false);
  const handleToggleFilter_1 = () => {
    setIsSortVisible(!isSortVisible);
  };
  const [date_picker, set_date_picker] = useState<any>('');
  const [toggler, setToggler] = useState(false);
  const [isVerifiedVisible, setIsVerifiedVisible] = useState(false);
  const [showGif, setShowGif] = useState(false);

  const timeout = setTimeout(() => {
    setShowGif(false);
  }, 4000);
  const [showGif_1, setShowGif_1] = useState(false);

  const timeout_1 = setTimeout(() => {
    setShowGif_1(false);
  }, 4000);
  const [showGif_2, setShowGif_2] = useState(false);
  const timeout_2 = setTimeout(() => {
    setShowGif_2(false);
  }, 4000);
  const [showGif_3, setShowGif_3] = useState(false);

  const timeout_3 = setTimeout(() => {
    setShowGif_3(false);
  }, 4000);
  const API_URL = process.env.REACT_APP_API_URL
  const { id } = useParams();
  const [items, setItems] = useState([]);
 
  const [course, setCourselist] = useState<any>({course_id:'',course_name:''});
  const [coursecount, setCourseCount] = useState<any>(0);
  const [lesson, setLesson] = useState<any>([]);

  const [membercount, setmember] = useState<any>([]);
  const [membersubcount, setmembersub] = useState<any>([]);
  // const [videourl, setVideoUrl] = useState<any>([]);
  const [lessonlist, setlessonlist] = useState<any>([]);
  const [youtube_url, setYoutubeUrl] = useState<any>('');

  const modifiedUrl = youtube_url.replace("watch?v=", "embed/") + "?disablekb=1";

  const [wiselist, setWiseList] = useState(0);
  const [like, setLike] = useState(0);
  const [enroll, setEnroll] = useState(false);

  const [likedcount, setLikedCount] = useState(0);
  const [thumbnail, setThumbnail] = useState();
  const [lesson_id, setLessonId] = useState('');
  const [like_course_id, setLikeCourseId] = useState('');
  const [wiselist_id, setWiseListId] = useState(0);
  const [lesson_name, setLessonName] = useState('');
  const [lesson_description, setLessonDescription] = useState('');
  const [lesson_count, setLessonCount] = useState(0);
  

  const getAnnouncementList = () => {
    const URL = `${API_URL}/member_announcement_list`;
    axios({
      method: 'get',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
    }).then((res) => {

      setItems(res.data.data);

    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }
  
  const viewCounts = (course_id:any,lesson_id:any)=>{

    const URL = `${process.env.REACT_APP_API_URL}/add_view_list`
    axios({
    method: 'post',
    url: URL,
    
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
    },
    data:{course_id:course_id,lesson_id:lesson_id}

    }).then((response) => {

        if(response.data.status == 200){
          
            notify('success',response.data.message);
            getmemberedit();
        }else{
       
            notify('error',response.data.message);
        }
        
    });
  }
  const LikeStatus = (course_id:any,lesson_id:any,like_course_id:any)=>{

    if(like == 1){
      setLike(0)
    }else{
      setLike(1)
    }

 
    var upd_value= 0
    if(value==0){
      upd_value=1
    }else{
      upd_value=0
    };
   

    const URL = `${process.env.REACT_APP_API_URL}/add_like_list`
    axios({
    method: 'post',
    url: URL,
    
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
    },
    data:{course_id:course_id,lesson_id:lesson_id,like_course_id:like_course_id}

    }).then((response) => {

        if(response.data.status == 200){
          
            notify('success',response.data.message);
            getmemberedit();
        }else{
       
            notify('error',response.data.message);
        }
        
    });
  }

  const AddWiselist = (course_id:any,lesson_id:any,wiselist_id:any)=>{
    if(like == 1){
      setWiseList(0)
          }else{
            setWiseList(1)
          }

    const URL = `${process.env.REACT_APP_API_URL}/add_view_course`
    axios({
    method: 'post',
    url: URL,
    
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
    },
    data:{course_id:course_id,lesson_id:lesson_id,wiselist_id:wiselist_id}

    }).then((response) => {

        if(response.data.status == 200){
          
            notify('success',response.data.message);
            getmemberedit();
        }else{
       
            notify('error',response.data.message);
        }
        
    });
  }

  const getLessonList = () => {
    const URL = `${API_URL}/lesson_list`;
    axios({
      method: 'put',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
    }).then((res) => {
  
      setLesson(res.data.data);
    
      
    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }

  const getmemberedit = () => {
      const URL = `${process.env.REACT_APP_API_URL}/edit_member_course_list/${id}`;
      axios({
          method: 'get',
          url: URL,
          withCredentials: false,
          headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`
          },
      }).then((res:any) => {

        
      if(res.data.count > 0){
        setCourselist(res.data.data[0]);
        setlessonlist(res.data.data[0].lesson_list);

        if(res.data.data[0].lesson_list.length > 0){
          setThumbnail(res.data.data[0].lesson_list[0].thumbnail_image );
          setLessonId(res.data.data[0].lesson_list[0].lesson_id);
          setLessonName(res.data.data[0].lesson_list[0].lesson_name );
          setLessonDescription(res.data.data[0].lesson_list[0].lesson_description );
          setLike(res.data.data[0].lesson_list[0].liked_course_id );
          setWiseList(res.data.data[0].lesson_list[0].wiselist_id );
          setYoutubeUrl(res.data.data[0].lesson_list[0].youtube_url );
          setLikeCourseId(res.data.data[0].lesson_list[0].liked_course_id );
          setWiseListId(res.data.data[0].lesson_list[0].wiselist_id );
          setLikedCount(res.data.data[0].lesson_list[0].liked_lesson_count );
        }

        
      }
      
    setCourseCount(res.data.count);

    console.log(lesson_id)
      // if (profile_pic) {
      //     setProfilepreview(profile_pic);
      // }
      });

  };

 
  const notify = (status:any,data:any) => {
  
    if(status == 'error'){
          toast.error(data, {
            style: {
              borderRadius: '10px',
              // border: '1px solid #FFFAEE',
              padding: '16px',
              color: '#FFFAEE',
              background:"#057091",
              
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#bf192a',
            },
          }
    );
    }else{
      toast.success(data, {
        style: {
          borderRadius: '10px',
          // border: '1px solid #FFFAEE',
          padding: '16px',
          color: '#ffc700 ',
          background:"#1C2241",
          
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#ffc700 ',
        },
      }
      );
    }
    
  } 
  
  useEffect(()=>{
    getLessonList();
    getmemberedit();
  },[ ])
  
  
  // useEffect(() => {
  //   if (youtube_url) {
  //     // Load the YouTube player API script
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag:any = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //     // Initialize the YouTube player when the API is ready
  //     const initializePlayer = () => {
  //     window.onYouTubeIframeAPIReady = () => {
  //       new window.YT.Player('youtube-player', {
  //         videoId: youtube_url.split('v=')[1],
  //         playerVars: {
  //           autoplay: 1,
  //           controls: 0, // Hide YouTube controls
  //           modestbranding: 1, // Hide YouTube logo in control bar
  //           showinfo: 0, // Hide video title and uploader info
  //           disablekb: 1, // Disable keyboard controls
  //           loop: 1, // Loop the video
  //           rel: 0, // Hide related videos at the end
  //           fs: 1 // Show full-screen button
  //         }
  //       });
  //     };
  //   }
  //   const checkYTObject = () => {
  //     if (window.YT && typeof window.YT.Player === 'function') {
  //       initializePlayer();
  //     } else {
  //       setTimeout(checkYTObject, 100);
  //     }
  //   };

  //   // Check if YT object is available
  //   checkYTObject();
  // }
  // }, [youtube_url]);
  return(
    <>
     <Toaster position="top-right"/>
      {/* <div className="app-main flex-column flex-row-fluid" id="kt_app_main" > */}
        {/* <div id="kt_app_content" className="app-content flex-column-fluid">
          <div id="kt_app_content_container" className="container-xxl"> */}
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-12'> 
              <div className="card px-4 pb-4"> 
                <div className="card-header border-0 pt-0 px-2">
                  <div className="card-title">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap">
                      <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0">Lessons</h1>
                      <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                      <li className="breadcrumb-item">
                          <Link to="/dashboard" className="pe-2">Home</Link>
                          <i className="fa-solid fa-angles-right"></i>
                      </li>
                      <li className="breadcrumb-item">Course</li>
                    </ul>
                    </div>
                  </div>
                  <div className="card-toolbar">
                  {/* <input className="form-check-input pt-2 checkme h-25px w-25px "  onChange={handleSelectAllChange} name="g" id="b3" type="checkbox" value="1"/>
                  <label className="ms-2 text-dark fw-bold d-block fs-6 me-3">Top Courses</label>
                  
                  */}
                      <div className="menu menu-sub menu-sub-dropdown w-250px w-md-200px menu-state-bg-light-primary" data-kt-menu="true" data-popper-placement="bottom-end">

                        <div className="px-3 py-3">
                        <div className="menu-item">
                          <p className="menu-link px-5 cursor-pointer">Ascending</p>
                        </div>
                          <div className="menu-item">
                          <p className="menu-link px-5 cursor-pointer">Descending</p> 
                          </div>
                          <div className="menu-item">
                          <p className="menu-link px-5 cursor-pointer">Date Modified</p> 
                          </div>
                        </div>
                      </div>
                    {/* <p className="cursor-ponter btn bg-hover-primary text-hover-white me-3 my-1 btn-sm" onClick={handleToggleFilter_1} id="tog_filter">
                        <span><i className="fa-solid fa-arrow-up-short-wide fs-3 text-primary"></i></span>
                    </a> */}
                    {/* <p className="cursor-ponter btn bg-hover-primary text-hover-white me-3 my-1 btn-sm" onClick={handleToggleFilter} id="tog_filter">
                        <span><i className="fa fa-filter fs-3 text-primary"></i></span>
                    </a> */}
                  </div>
                  </div> 
                  <div className="my_filter" style={{ display: isFilterVisible ? 'block' : 'none' }}>
                  <div className='row mt-2 px-2'>
                    
                      <div className="col-lg-3">
                        <label className="form-label fw-bold">Category</label>
                        <div>
                            <select className="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_637dc7679e179" data-allow-clear="true"  onChange={(e)=>{setVisitId(e.target.value)}}>
                                <option value="all">All</option>
                                <option value="online">Online Marketing</option>
                                <option value="foreign">Foreign Trade</option>
                            </select>
                        </div> 
                      </div>
                      <div className="col-lg-3">
                        <label className="form-label fw-bold">Course</label>
                        <div>
                            <select className="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_637dc7679e179" data-allow-clear="true"  onChange={(e)=>{setVisitId(e.target.value)}}>
                                <option value="all">All</option>
                                <option value="online">Online Marketing</option>
                                <option value="foreign">Foreign Trade</option>
                            </select>
                        </div> 
                      </div>
                      <div className="col-lg-3">
                        <label className="form-label fw-bold">Lesson</label>
                        <div>
                            <select className="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_637dc7679e179" data-allow-clear="true"  onChange={(e)=>{setVisitId(e.target.value)}}>
                                
                                <option value="all">All</option>
                                <option value="lesson1">Marketing 1</option>
                                <option value="lesson2">Marketing 2</option>
                            </select>
                        </div> 
                      </div>
                      <div className="col-lg-3">
                        <label className="form-label fw-bold">Cluster</label>
                        <div>
                            <select className="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_637dc7679e179" data-allow-clear="true"  onChange={(e)=>{setVisitId(e.target.value)}}>
                                
                                <option value="all">All</option>
                                <option value="all">Agri</option>
                                <option value="all">Stones</option>
                                <option value="all">Engineering</option>
                            </select>
                        </div>   
                      </div>
                        <div className="col-lg-3">
                          <label className='form-label fw-bold'>Select Date</label>
                          <select name="date_picker" id="date_picker" data-control="select2" className="form-select form-select-solid" onChange={(e)=>set_date_picker(e.target.value)}>
                            <option value="all">All</option>
                            <option value="today">Today</option>
                            <option value="this_week">This Week</option>
                            <option value="this_year">This Year</option>
                            <option value="date_range">Date Range</option>
                          </select>
                        </div>
                        {
                          date_picker === 'date_range' ? (
                            <>
                            <div className="col-lg-3">
                              <label className='fs-6 fw-semibold'>From date</label>
                              <input type='date' className='form-control form-control-lg form-control-solid' name='wedd_date' placeholder='Wedding Date' />
                            </div>
                            <div className="col-lg-3">
                              <label className='fs-6 fw-semibold'>End date</label>
                              <input type='date' className='form-control form-control-lg form-control-solid' name='wedd_date' placeholder='Wedding Date' />
                            </div></>
                          ):('')
                        } 
                      <div className="col-lg-3">
                      <button type="button" className="btn btn-primary btn-sm mt-8">Go</button>
                      </div>
                    </div>
                </div>
              
                  <div className="pt-2 separator border-primary">
                  </div>
                  <div className='row pt-4'>
                    <div className='col-lg-12 pb-4'>
                      <div className="card bg-light-primary shadow hoverabl">
                        <div className="card-body">
                        <div className='row'>
                        <h1> <label className="pt-2 fw-bold text-dark  mb-3">{course.course_name}</label>
                         </h1>
                          <div className='col-md-8 col-sm-12' >
{/*                             
                          <div style={{ width: '100%', minHeight: '400px'}}>
                            <div style={{ position: 'relative', width: '100%', minHeight: '100%' }}>
                              {youtube_url && 
                      
                                <ReactPlayer
                                  className='react-player'
                                  url={youtube_url}
                                  width='100%'
                                  height='100%'
                                  controls={true}
                                  style={{pointerEvents:'none'}}
                                  config={{
                                    youtube: {
                                      playerVars: {
                                        controls: 0,
                                        modestbranding: 1,
                                        rel: 0, // Disable related videos
                                        autohide: 1, // Autohide player controls
                                        showinfo: 0, // Hide video title and uploader information
                                        wmode: 'transparent' // Ensure transparency
                                      }
                                    }
                                  }}
                                />
                            
                              }
                              <style>
                                {`
                                   .react-player .ytp-share-button {
                                    display: none !important;
                                  }
                                `}
                              </style>
                            </div>
                          </div> */}

                            {/* <div className='d-flex justify-content-center'>
                              <div style={{ width: '100%', minHeight: '400px'}}>
                                {youtube_url && 
                                 <ReactPlayer
                                 className='react-player'
                                 url={youtube_url}
                                 width='100%'
                                 height='100%'
                                 controls={true}
                                 config={{
                                   youtube: {
                                     playerVars: {
                                       controls: 1,
                                       modestbranding: 1
                                     }
                                   }
                                 }}
                               />
                                          // <ReactPlayer
                                          // playing={true}
                                          // loop={true}
                                          //     controls={true}
                                          //     url={youtube_url}
                                          //     width={"100%"}
                                          //     height={"100%"}
                                          //     config={{
                                          //     youtube:{
                                          //       playerVars:{
                                                  
                                          //       }
                                          //     }
                                          //     }}
                                              
                                          //   />
                                }
                              </div>
                            </div> */}
                             {/* <div style={{ position: 'relative', paddingTop: '56.25%', }}>
                                  <ReactPlayer
                                    className='react-player'
                                    url={youtube_url}
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                    config={{
                                      youtube: {
                                        playerVars: {
                                          controls: 1,
                                          modestbranding: 1
                                        }
                                      }
                                    }}
                                  />
                                </div> */}
                          {/* <div className='d-flex justify-content-center' style={{ minHeight: '400px' }}>
                            <div style={{ position: 'relative', width: '100%', minHeight: '100%' }}>
                              {youtube_url && 
                              <div style={{ pointerEvents:'none' }}>
                                <ReactPlayer
                                  playing={true}
                                  loop={true}
                                  controls={true}
                                  url={youtube_url}
                                  width={"100%"}
                                  height={"100%"}
                                  //  style={{ position: 'absolute', top: 0, left: 0 }}
                                  config={{
                                    youtube: {
                                      playerVars: {
                                        // controls: 1,
                                        // modestbranding: 1
                                      
                                      }
                                    }
                                  }}
                                
                                />
                               </div>
                              }
                            </div>
                          </div> */}
             {/* <div className='d-flex justify-content-center' style={{ minHeight: '400px' }}>
                <div style={{ position: 'relative', width: '100%', minHeight: '100%' }}>
                  {youtube_url && 
                    <ReactPlayer
                      playing={true}
                      loop={true}
                      controls={true}
                      url={youtube_url}
                      width={"100%"}
                      height={"100%"}
                      style={{ position: 'absolute', top: 0, left: 0 }}
                      config={{
                        youtube: {
                          playerVars: {
                            controls: 1,
                            modestbranding: 1,
                            rel: 0, 
                            autohide: 1, 
                            showinfo: 0, 
                          }
                        }
                      }}
                    
                    />
                  }
                  </div>
              </div> */}


            <div className='d-flex justify-content-center' style={{ minHeight: '400px' }}>
              <div style={{ position: 'relative', width: '100%', minHeight: '100%' }}>
                {youtube_url && 
                  <ReactPlayer
                    playing={false}
                    loop={true}
                    controls={false}
                    url={youtube_url}
                    width={"100%"}
                    height={"100%"}
                    style={{ position: 'absolute', top: 0, left: 0 }}
                  
                  />
                }
              </div>
            </div>
 
 {/* <div className='d-flex justify-content-center' style={{ minHeight: '400px' }}>
  <div style={{ position: 'relative', width: '100%', minHeight: '100%' }}>
    {youtube_url && 
      <ReactPlayer
        playing={true}
        loop={true}
        controls={false} // Set controls to false to hide ReactPlayer controls
        url={youtube_url}
        width={"100%"}
        height={"100%"}
        config={{
          youtube: {
            playerVars: {
              controls: 0, // Hide YouTube controls
              modestbranding: 1, // Hide YouTube logo in control bar
              showinfo: 0, // Hide video title and uploader info
              autoplay: 1, // Autoplay the video
            }
          }
        }}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
    }
  </div>
</div> */}





           {/* <div className='d-flex justify-content-center' style={{ minHeight: '400px' }}>
      <div style={{ position: 'relative', width: '100%', minHeight: '100%' }}>
        {youtube_url && 
          <ReactPlayer
            playing={true}
            loop={true}
            controls={true}
            url={youtube_url}
            width={"100%"}
            height={"100%"}
            style={{ position: 'absolute', top: 0, left: 0 }}
            config={{
              youtube: {
                playerVars: {
                  controls: 1,
                  modestbranding: 1,
                  rel: 0, // Disable related videos
                  autohide: 1, // Autohide player controls
                  showinfo: 0, // Hide video title and uploader information
                }
              }
            }}
            onReady={handlePlayerReady}
            onError={handlePlayerError}
          />
        }
        {playerReady && removeShareButton()}
      </div>
    </div> */}
     





                            {/* {youtube_url && 
                                  <ReactPlayer
                                    playing={true}
                                    loop={true}
                                    controls={true}
                                    url={youtube_url}
                                    width={"100%"}
                                    height={"100%"}
                                    config={{
                                      youtube: {
                                        playerVars: {
                                          modestbranding: 1,
                                          disablekb: 1, // This disables the YouTube keyboard control
                                          rel: 0, // This disables related videos
                                          showinfo: 0, // This disables the video title and uploader information
                                          controls: 1, // This ensures that controls are shown
                                        }
                                      }
                                    }}
                                  />
                                } */}
                            <div className='d-block align-items-center'>
                              {/* <label className="pt-2 text-center fw-bold text-dark fs-3 d-block mb-3">{course.course_name}</label> */}

                                  <label className="fw-bold text-center text-dark fs-3 mb-3 d-block">
                                  {lesson_name}
                                 </label>
                                 <label className="fw-bold text-center text-dark fs-5 mb-3 d-block">
                                  {lesson_description}
                                 </label>
                              
                              <label className="fw-bold text-center text-dark fs-6 mb-3  d-flex justify-content-center">
                                <span className="badge badge-success text-black fs-6 me-3">{coursecount} Lessons</span>
                                <span className='me-0'> {

                                  <i className={like > 0?('fs-1 fa-heart fa-solid shadow-txt'):('fs-1 fa-heart fa-regular')} 
                                  onClick={() => {LikeStatus(course.course_id,lesson_id,like_course_id) }} 
                                  title='Liked' style={{color: "red"}}></i>

                                  } 
                                </span>
                                <span className="px-2 text-black fs-4">{likedcount} Likes</span>
                              </label>
                            </div>
                           
                          </div>
                      
                          <div className='col-md-4 col-sm-12'>
                            <div className='scroll-y py-2' style={{maxHeight:'450px'}}>
                            { lessonlist?.map((lesson:any,i:any)=>{
                              const lessonNumber = lessonlist.length - i;
                            return   <div className='d-flex justify-content-start flex-column' key={i}>
                                <label>
                                  <Link to=""
                                  className="fw-bold text-hover-primary  fs-5"
                                  data-fslightbox="lightbox-youtube"
                                  onClick={() => {setYoutubeUrl(lesson.youtube_url);
                                    
                                    setThumbnail(lesson.thumbnail_image);
                                    setLessonId(lesson.lesson_id);
                                    setLessonName(lesson.lesson_name);
                                    setLessonDescription(lesson.lesson_description);
                                    setLike(lesson.liked_course_id);
                                    setWiseList(lesson.wiselist_id);
                                    setWiseListId(lesson.wiselist_id);
                                    setLikeCourseId(lesson.liked_course_id);
                                    setYoutubeUrl(lesson.youtube_url);
                                      viewCounts(lesson.course_id,lesson.lesson_id);
                                    


                                  
                                  }}>
                                    {/* <div className='fw-bold fs-5'>Lesson {i+1}</div> */}

                                    {/* {lesson.lesson_id==lesson_id ?(<p className={'ms-6 text-hover-warning fw-semibold fs-6 text-warning'} >{lesson.lesson_name}</p>):( 
                                       <p className={'ms-6 text-hover-warning fw-semibold fs-6 '} >{lesson.lesson_name}</p>)
                                    } */}

                                    {/* <div className='fw-bold fs-5'>Lesson {i+1}</div>
                                    {
                                        lesson.lesson_id === lesson_id ? (
                                            <p className={'ms-6 text-hover-warning fw-semibold fs-6 text-warning'} style={{ border: '2px solid #FFC107', padding: '5px', borderRadius: '5px' }}>
                                                {lesson.lesson_name}
                                            </p>
                                        ) : (
                                            <p className={'ms-6 text-hover-warning fw-semibold fs-6'} style={{ border: '2px solid #E0E0E0', padding: '5px', borderRadius: '5px' }}>
                                                {lesson.lesson_name}
                                            </p>
                                        )
                                    } */}
                               
                                    <div className='fw-bold fs-5' style={{ marginBottom: '10px', color: '#2196F3' }}>Lesson {lessonNumber}</div>
                                    <div>
                                        {
                                            lesson.lesson_id === lesson_id ? (
                                                <p className={'ms-6 text-hover-warning fw-semibold fs-6 text-warning'} style={{ border: '2px solid #FFC107', padding: '5px', borderRadius: '5px' }}>
                                                    {lesson.lesson_name}
                                                </p>
                                            ) : (
                                                <p className={'ms-6 text-hover-warning fw-semibold fs-6'} style={{ border: '2px solid #E0E0E0', padding: '5px', borderRadius: '5px' }}>
                                                    {lesson.lesson_name}
                                                </p>
                                            )
                                        }
                                    </div> 

                                  </Link>
                                  {/* <ReactPlayer
                                  controls={true}
                                  url='https://www.youtube.com/watch?v=YijrhLSRtaU&embeds_referring_euri=https%3A%2F%2Fepcindia.org%2F&source_ve_path=Mjg2NjY&feature=emb_logo'
                                /> */}
                                
                                </label>
                              </div>
                            })}


                            {/* <FsLightbox  toggler={toggler} sources={videourl} /> */}
                        
                            </div>
                      

                          </div>               
                            {/* {youtube_url && 

                                <FsLightbox
                                    toggler={toggler}
                                    sources={[
                                      ' https://www.youtube.com/watch?v=YijrhLSRtaU&embeds_referring_euri=https%3A%2F%2Fepcindia.org%2F&source_ve_path=Mjg2NjY&feature=emb_logo'
                                    ]}
                                  />
                            } */}                        
                               </div>
                             </div>
                          </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>  
      {/* </div> */}
      {open ?(
          <div className= {open ? "modal fade show bg-black bg-opacity-50 d-block":"modal fade show bg-black bg-opacity-50 d-none"} id='kt_modal_add_company' tabIndex={-1} aria-hidden={true}>
          
          
            {
              open_model == 'view' ? (<>
                <div className='modal-dialog modal-md mt-5'>
                  <div className='modal-content rounded' style={{backgroundColor: "#ffffff"}}>
                    <View isOpen={isOpenstatus} />
                  </div>
                </div>
                </>):(<></>)
            }
            {
              open_model == 'annoucement_view' ? (<>
                <div className='modal-dialog modal-md mt-5'>
                  <div className='modal-content rounded' style={{backgroundColor: "#ffffff"}}>
                    <Announcement_view isOpen={isOpenstatus} />
                  </div>
                </div>
                </>):(<></>)
            }
            
          </div>
        ):(
          <></>
        )
      }             
    </>
  
  )
}

export {Course}


declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}
declare global {
  interface Window {
    YT: {
      Player: any; // Adjust the type according to your needs
    };
  }
}
