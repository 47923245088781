import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import { Link } from 'react-router-dom'
import moment from 'moment';
import axios from 'axios';
import {useAuth} from '../../../../app/modules/auth'
import {FC, useEffect, useState,useContext} from 'react'
const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'


const Navbar = () => {
  const {config} = useLayout();
  const [notification, setNotification] = useState();
  const {currentUser, logout} = useAuth()
  const [alerts, setAlert] = useState<any>([]);
  const [newnotify, setNew]   = useState([]);
  const [remainders, setRemainder] = useState([]);
  const [request, setRequest] = useState([]);
  const [count ,  setCount] = useState<any>(currentUser?.notify_count);
  const  duration =(start_time :any)=> {

    let st:any = moment(start_time).toISOString();
    let ed:any = moment().toISOString();
  
    var a = moment(ed);//now
    var b = moment(st)
  ;


    let time_elapsed = a.diff(b, 'seconds');



    let seconds    = time_elapsed ;
    let minutes    = Math.round(time_elapsed / 60 );
    let hours      = Math.round(time_elapsed / 3600);
    let days       = Math.round(time_elapsed / 86400 );
    let weeks      = Math.round(time_elapsed / 604800);
    let months     = Math.round(time_elapsed / 2600640 );
    let years      = Math.round(time_elapsed / 31207680 );


    
    // Seconds
    if(seconds <= 60){
        return "just now";
    }
    //Minutes
    else if(minutes <=60){
        if(minutes==1){
            return "one minute ago";
        }
        else{
            return minutes +" minutes ago";
        }
    }
    //Hours
    else if(hours <= 24){
        if(hours==1){
            return " 1 hour ago";
        }else{
            return hours  + " hrs ago";
        }
    }
    //Days
    else if(days <= 7){
        if(days==1){
            return "yesterday";
        }else{
            return days+ " days ago";
        }
    }
    //Weeks
    else if(weeks <= 4.3){
        if(weeks==1){
            return "1 week ago";
        }else{
            return weeks+ " weeks ago";
        }
    }
    //Months
    else if(months <=12){
        if(months==1){
            return "1 month ago";
        }else{
            return months + " months ago";
        }
    }
    //Years
    else{
        if(years==1){
            return "1 year ago";
        }else{
            return years + " years ago";
        }
    }
  }
  const [logo, setLogo] = useState<any>();

  const getgenerallelist=()=>{
    const URL = `${API_URL}/generall_member`
    axios({
      method: 'get',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
    }).then((res) => {

    

      setLogo(res.data.data.default_pic);
    
    
    
    });
    
  }
  const API_URL = process.env.REACT_APP_API_URL

  const notifing=()=>{
    const URL = `${API_URL}/notification_member`
        axios({
          method: 'get',
          url: URL,
          headers: {
            
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization': `Bearer ${sessionStorage.getItem("token")}`
          },
        }).then((res) => {

          setCount(res.data.count);
          const alert = res.data.data.filter((st:any) => {
            return st.status == 1;
          });
        

          const newnotify = res.data.data.filter((st:any) => {
            return st.alert_status == 1;
          });

          const remainder = res.data.data.filter((st:any) => {
            return st.alert_status == 2;
          });

          const request = res.data.data.filter((st:any) => {
            return st.alert_status == 3;
          });


          let arr_alert:any = [];

            alert?.map((data:any)=>{

            

              arr_alert.push({title:data.title, description:data.content, time:duration(data.created_at), state:'info',icon: 'icons/duotune/general/gen044.svg',})
            })
            
            let arr_new:any = [];

            newnotify?.map((data:any)=>{
              arr_new.push({title:data.title, description:data.content, time:duration(data.created_at), state:'info',logo:'icons/duotune/general/gen043.svg'})
            })
            
            let arr_remainder:any = [];

            remainder?.map((data:any)=>{

              arr_remainder.push({code:data.title, state:'success', message:data.content, time:duration(data.created_at)})
            })
            
            let arr_request:any = [];

            request?.map((data:any)=>{
              
              arr_request.push({code: data.title, state: 'danger', message: data.content, time: duration(data.created_at)},)
            })
            
          
            
          setAlert(arr_alert);
          setNew(arr_new);
          setRemainder(arr_remainder);
          setRequest(arr_request);

        });
  }

  const check_count= ()=>{
    setCount(0);
  }
  useEffect(() => {

    getgenerallelist();
    notifing();

  },[]);
  return (
    <div className='app-navbar flex-shrink-0'>

      <div className={clsx('app-navbar-item me-2', itemClass)}>
        <Link to ="course_view" className="btn btn-primary text-hover-black me-3 my-1 btn-sm" id="tog_filter">
        <KTSVG path="/media/icons/duotune/social/soc007.svg" className="svg-icon-muted svg-icon-2"/>Courses</Link>
      </div>

      <div className={clsx('app-navbar-item me-2', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          onClick={notifing}
          className={clsx('position-relative', btnClass)}
        >
           {
            !count ? (
              <i className="fa-solid fa-bell fs-2"></i>
            ):(<> <i className="fa-solid fa-bell fs-2"></i>
            <label className="btn btn-icon btn-circle w-20px h-20px fs-8 bg-white text-primary fw-bold shadow mb-6 mt-2 position-absolute translate-middle top-0 start-75">{count > 99 ? (<><b>99<sup>+</sup></b></>):(count)}</label></>)
          }
          {/* <i className="fa-solid fa-bell fs-2"></i>
          <label className="btn btn-icon btn-circle w-20px h-20px fs-8 bg-white text-primary fw-bold shadow mb-6 mt-2 position-absolute translate-middle top-0 start-75">10</label> */}
        
        </div>
        {/* </div> */}
        <HeaderNotificationsMenu alert={alerts}   count={check_count}  />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/images/staff.png')} alt='' /> */}
          {
            currentUser?.pic ? ( <img src={currentUser.pic} alt='' />):( <img src={toAbsoluteUrl('/images/Team.jpg')} alt='' />)
          }
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
