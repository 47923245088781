import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link , useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'

import toast, { Toaster } from 'react-hot-toast';
import axios, { AxiosError } from 'axios';
const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    // .min(3, 'Minimum 3 symbols')
    // .max(10, 'Maximum 10 digit')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('');
  const navigate= useNavigate();
  const [otpnumber, setOtpNumber] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: forgotPasswordSchema,
  //   onSubmit: (values, {setStatus, setSubmitting}) => {
  //     setLoading(true)
  //     setHasErrors(undefined)
  //     setTimeout(() => {
  //       requestPassword(values.email)
  //         .then(({data: {result}}) => {
  //           setHasErrors(false)
  //           setLoading(false)
  //         })
  //         .catch(() => {
  //           setHasErrors(true)
  //           setLoading(false)
  //           setSubmitting(false)
  //           setStatus('The login detail is incorrect')
  //         })
  //     }, 1000)
  //   },
  // })
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: forgotPasswordSchema,

  //   onSubmit: (values, {setStatus, setSubmitting}) => {
  //     setLoading(true)
  //     //   setHasErrors(undefined)

  //     const URL = `${process.env.REACT_APP_API_URL}/email_reset_password`
  //     axios({
  //     method: 'post',
  //     url: URL,

  //     headers: {
  //         'Access-Control-Allow-Origin' : '*',
  //         'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  //         // 'Authorization': `Bearer ${sessionStorage.getItem("token")}`
  //     },
  //    data:values
  //   }).then((response) => {
  //       if (!response.ok:any) {
  //         // The response was an error status, so handle it accordingly
  //         return response.json().then((data:any) => {
  //           throw new Error(data.message || 'An error occurred');
  //         });
  //       }
  //       // Parse the JSON data from the successful response
  //       return response.json();
  //     })
  //     .then((data) => {
  //       // Check the status code or any relevant fields in the data
  //       if (data.status === 200) {
  //         notify('success', data.message);
  //       } else {
  //         notify('error', data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle any errors thrown in the previous steps
  //       console.error('Error:', error.message);
  //       notify('error', error.message);
  //   });
  //   },
  // })
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,

    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      // setHasErrors(undefined)

      const URL = `${process.env.REACT_APP_API_URL}/email_reset_password`;

      axios({
        method: 'post',
        url: URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          // 'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
        data: values
      })
      .then((response) => {
        const data = response.data;
        console.log(data);
        if (data.status === 200) {
          notify('success', data.message);
          setSuccessMessage(data.message);
          // setTimeout(() => {
          //   navigate('/auth', { replace: true });
          // }, 1000);
        } else if(data.status === 401) {
          notify('error', "checking");
        }
      })
      .catch((error) => {
        // Handle any errors from axios
        const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
        console.error('Error:', errorMessage);
        notify('error', errorMessage);
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
    },
  });
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: forgotPasswordSchema,

  //   onSubmit: async (values, { setStatus, setSubmitting }) => {
  //     setLoading(true);

  //     const URL = `${process.env.REACT_APP_API_URL}/email_reset_password`;

  //     try {
  //       const response = await axios.post(URL, values, {
  //         headers: {
  //           // If authentication is required, uncomment the following line:
  //           // 'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
  //           'Content-Type': 'application/json', // Ensure the correct content type is specified
  //         },
  //       });

  //       const { data } = response;

  //       if (data.status === 200) {
  //         notify('success', data.message);
  //         // Uncomment and adjust if you need to navigate after a successful response
  //         // setTimeout(() => {
  //         //   navigate('/auth', { replace: true });
  //         // }, 3000);
  //       } else {
  //         notify('error', data.message);
  //         setStatus(data.message);
  //       }
  //     } catch (error) {
  //       // TypeScript assertion to handle AxiosError
  //       const axiosError = error as AxiosError;

  //       if (axiosError.response && axiosError.response.data) {
  //         const { message, error_msg } = axiosError.response.data;
  //         setStatus(message || error_msg || 'An error occurred');
  //         notify('error', message || error_msg || 'An unexpected error occurred');
  //       } else {
  //         setStatus('Network or server error');
  //         notify('error', 'Network or server error');
  //       }
  //     } finally {
  //       setLoading(false);
  //       setSubmitting(false);
  //     }
  //   },
  // });
  const notify = (status:any,data:any) => {

    if(status == 'error'){
          toast.error(data, {
            style: {
              borderRadius: '10px',
              // border: '1px solid #FFFAEE',
              padding: '16px',
              color: '#FFFAEE',
              background:"#057091",

            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#bf192a',
            },
          }
    );
    }else{
      toast.success(data, {
        style: {
          borderRadius: '10px',
          // border: '1px solid #FFFAEE',
          padding: '16px',
          color: '#ffc700 ',
          background:"#1C2241",

        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#ffc700 ',
        },
      }
      );
    }

  }

  return (

    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}

    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        {/* end::Title */}

      </div>


      {/* begin::Title */}
      {/* {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )} */}
      {/* Show success message if applicable */}
      {successMessage && (
        <div className='flex items-center mb-10 bg-light-info p-8 rounded '>
         
          <div className='text-info fs-5'>   <i className='fas fa-envelope text-info fs-4 mr-4 me-2'></i>
           Request Successfully. Please check your Email 
          </div>
        </div>
       )} 
      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your phone</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
     {
       !otpnumber && <div className='fv-row mb-8'>
       <label className='form-label fw-bolder required text-gray-900 fs-6'>Email</label>
       <input
         type='text'
         placeholder=''
         autoComplete='off'
         {...formik.getFieldProps('email')}
         className={clsx(
           'form-control bg-transparent',
           {'is-invalid': formik.touched.email && formik.errors.email},
           {
             'is-valid': formik.touched.email && !formik.errors.email,
           }
         )}
       />
       {formik.touched.email && formik.errors.email && (
         <div className='fv-plugins-message-container'>
           <div className='fv-help-block'>
             <span role='alert'>{formik.errors.email}</span>
           </div>
         </div>
       )}
     </div>
     }
      {/* {
        otpnumber &&  <div className='fv-row mb-8'>
        <label className='form-label fw-bolder required text-gray-900 fs-6'>OTP Number</label>
        <input
          type='number'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('otp')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.otp_number && formik.errors.otp_number},
            {
              'is-valid': formik.touched.otp_number && !formik.errors.otp_number,
            }
          )}
        />
        {formik.touched.otp_number && formik.errors.otp_number && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.otp_number}</span>
            </div>
          </div>
        )}
      </div>
      } */}


      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>

      {/* {
       !otpnumber &&  <button type='submit' id='kt_password_reset_submit' onClick={()=>{setOtpNumber(true);sessionStorage.setItem('email',formik.values.email); }} className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>} */}
        {
       !otpnumber &&  <button type='submit' id='kt_password_reset_submit'  className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>}


        {/* {
       otpnumber &&     <Link to='/auth/password_change'> <button type='submit' id='kt_password_reset_submit' onClick={()=>setOtpNumber(true)} className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button> </Link>} */}

        {/* {
       otpnumber &&      <button type='submit' id='kt_password_reset_submit' onClick={()=>setOtpNumber(true)} className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button> } */}
        {/* </Link> */}
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
