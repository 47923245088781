/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import toast, { Toaster } from 'react-hot-toast';
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})


// const initialValues = {
//   email: 'jack@gmail.com',
//   password: '9874561230',
//   is_login: 'member',
// }
const initialValues = {
  email: '',
  password: '',
  is_login: 'member',
}


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password,values.is_login)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error:any) {
        console.error(error)
        saveAuth(undefined)
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          const { message, error_msg } = error.response.data;
          if (message) {
            setStatus(message);
            notify('error', message);
          } else if (error_msg) {
            setStatus(error_msg);
            notify('error', error_msg);
          } else {
            setStatus('Unauthorized');
            notify('error', 'Unauthorized');
          }
        } else {
          setStatus('An unexpected error occurred');
          notify('error', 'An unexpected error occurred');
        }
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        // notify('error', 'The login details are incorrect');
      }
    },
  })

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const notify = (status:any,data:any) => {

    if(status == 'error'){
          toast.error(data, {
            style: {
              borderRadius: '10px',
              // border: '1px solid #FFFAEE',
              padding: '16px',
              color: '#FFFAEE',
              background:"#e02248",
            
              
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#ff4136',
            },
          }
    );
    }else{
      toast.success(data, {
        style: {
          borderRadius: '10px',
          // border: '1px solid #FFFAEE',
          padding: '16px',
          color: '#FFFAEE',
          background:"#1C2241",
          
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#1f8f1f',
        },
      }
      );
    }
    
  }
  return (
  <>
    <Toaster position="top-right"/>
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Member Login</h1>
        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* begin::Heading */}


      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='required form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-3'>
        <label className='required form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <div className="input">
        {" "}
          <input
            type={passwordShown ? "text" : "password"}
            autoComplete='off'
            
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <div className="form-check form-check-custom form-check-solid mt-2">
              <input className="form-check-input" onClick={togglePasswordVisiblity} type="checkbox" value="" id="flexCheckDefault"/>
              <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Password
              </label>
          </div>
          </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-3'>
        <label className='required form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
          
        )}
      </div> */}
      
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> 
        </div>
        {/* end::Link
     
      {/* end::Wrapper */}

      {/* begin::Action */}
      
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Get In</span>}
          {/* <span><i className="ms-2 fa-solid fa-arrow-right"></i></span> */}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  </>
  )
}


