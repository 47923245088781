/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-2'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */} {/* begin::Links */}
        {/* <div className='d-flex flex-center flex-wrap px-5'>
         
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
          
        </div> */}
        {/* end::Footer */}
      </div>
      {/* end::Body */}
      {/* end::Links */}
      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-1'
        // style={{backgroundImage: `url(${toAbsoluteUrl('/images/epc_bg.jpg')})`}}
        style={{backgroundImage: `url(${toAbsoluteUrl('/images/epc_menu_header.png')})`}}

      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mt-4'>
            {/* <img alt='Logo' src={toAbsoluteUrl('/images/epc_logo.png')} className='h-150px w-250px' /> */}
            <img alt='Logo' src={toAbsoluteUrl('/images/epc_logo_1.png')} className='h-150px w-250px' />

          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='mx-auto w-300px w-md-50 w-xl-600px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/images/login_bg_epc_1.png')}
            alt=''
          />
          
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
