import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  FeedsWidget2,
  FeedsWidget3,
  FeedsWidget4,
  FeedsWidget5,
  FeedsWidget6,
  ChartsWidget1,
  ListsWidget5,
  ListsWidget2,
} from '../../../_metronic/partials/widgets'

export function Profile() {
  return (
    <div className='row g-5 g-xxl-8'>
       <div className="app-main flex-column flex-row-fluid" id="kt_app_main" >
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div id="kt_app_content_container" className="container-xxl">
                <div className="card"> 
                  <div className="card-header border-0 pt-0">
                    <div className="card-title">
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0">Profile Details</h1>
                      </div>
                    </div>
                  </div>
                  <div className="separator border-light-primary"></div>         
                  <div className="card-body py-4">
                    <div className='row'>
                    {/* <div className="col-lg-4">
                    <label className='col-form-label required fw-semibold fs-6'>Subscriber ID</label>
                    <input type='text' className='form-control form-control-lg form-control-solid fs-6' name='title' placeholder='Enter Subscriber ID' />
                  </div>
                  <div className="col-lg-4">
                    <label className='col-form-label required fw-semibold fs-6'>Subscriber Name</label>
                    <input type='text' className='form-control form-control-lg form-control-solid fs-6' name='title' placeholder='Enter Subscriber Name' />
                  </div> */}
                      <div className='col-lg-6'>
                        <div className='row'>
                          <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Profile Picture</label>
                          <div className="col-lg-8">
                            <div className=" shadow image-input" data-kt-image-input="true" >
                              <div className="image-input-wrapper w-125px h-125px">
                                <img alt='Logo' src={toAbsoluteUrl('/images/staff.png')} className='image-input-wrapper w-125px h-125px'/>
                              </div>
                              <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                <i className="bi bi-pencil-fill fs-7"></i>
                                  <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                  <input type="hidden" name="avatar_remove" />
                              </label>
                              <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                <i className="bi bi-x fs-2"></i>
                              </span>
                              <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                <i className="bi bi-x fs-2"></i>
                              </span>
                            </div>
                            <div className="form-text pt-2">Allowed file types: png, jpg, jpeg.</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='row'>
                          <div className="col-lg-12">
                            <label className='col-form-label required fw-semibold fs-6'>Name</label>
                            <input type='text' className='form-control form-control-lg form-control-solid' name='usr_name' value={"Praveen"} placeholder='Enter Name' />
                          </div>
                          <div className="col-lg-12">
                            <label className='col-form-label required fw-semibold fs-6'>Email ID</label>
                            <input type='text' className='form-control form-control-lg form-control-solid' name='usr_email_id' value={"user1@gmail.com"} placeholder='Enter Email ID' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end py-6'>  
                      <button className='btn btn-primary'  type="submit">Update</button>
                    </div>        
                  </div>
                </div>
              </div>
            </div>
      </div> 
    </div>
  )
}
