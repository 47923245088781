/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl, defaultAlerts, defaultLogs} from '../../../helpers'
import axios from 'axios';
import { render } from 'sass';


const HeaderNotificationsMenu =({alert,count}:any) => {


  const Clear =({isOpen}:any)=>{
    const URL = `${process.env.REACT_APP_API_URL}/notification_clear_member`
    axios({
    method: 'put',
    url: URL,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
    },
    
    }).then((response) => {
        if(response.status == 200){
        
          // isOpen();   
          count();  
        } 
        
    });
  
  }

  return(
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
  >
      <div
      className='d-flex flex-column bgi-no-repeat rounded-top'
      style={{backgroundImage: `url('${toAbsoluteUrl('/images/epc_notifications.jpg')}')`}}
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
        Notifications
      </h3>
      <a className='text-white text-hover-secondary cursor-pointer text-end px-5  mb-1' onClick={()=>{Clear(0)}} >
        Mark as read
      </a>
      {/* <ul className='fw-bold px-9'>
        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_1'
          >  Alerts
          </a>
        </li>
      </ul> */}
      {/* <div className='ms-12 pb-4'>
        <label className='text-white fw-bolder'>Alerts</label>
      </div> */}
    </div>

    {/* <div className='tab-content'>
      <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'> */}
        <div className='scroll-y mh-325px my-5 px-8'>
          {alert.map((alert:any, index:any) => (
            <div key={`alert${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                    {alert.title}
                  </a>
                  <div className='text-gray-400 fs-7'>{alert.description}</div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>{alert.time}</span>
            </div>
          ))}
        </div>

        {/* <div className='py-3 text-center border-top'>
          <Link
            to='/crafted/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
          </Link>
        </div> */}
      {/* </div>
    </div> */}
  </div>
  )
}

export {HeaderNotificationsMenu}
