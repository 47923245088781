import React, {ChangeEvent, useEffect, useState,useRef} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link , useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import axios from 'axios';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

export const initialValues = {
  password: '',
  current_password: '',
  // phone_number: sessionStorage.getItem('phone_number'),

}

export const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
    // .email('Wrong email format')
    // .min(3, 'Minimum 3 symbols')
    .max(10, 'Maximum 10 digit')
    .required('Password is required'),
    current_password: Yup.string()
    // .email('Wrong email format')
    // .min(3, 'Minimum 3 symbols')
    .max(10, 'Maximum 10 digit')
    .required('Password is required'),
})

export function ForgotPasswordChange() {
  const location = useLocation();
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)
  const navigate= useNavigate();
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
   useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tokenFromUrl = query.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      setError('Invalid token.');
    }
  }, [location.search]);

  const formik = useFormik({
    initialValues: {
      password: '',
      current_password: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setStatus('');

      axios.post(`${process.env.REACT_APP_API_URL}/member_update_password`, {
        ...values,
        token, // Include the token in the request data
      }, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        }
      })
      .then((response) => {
        if (response.data.status === 200) {
          notify('success', response.data.message);
          setTimeout(() => {
            navigate('/auth', { replace: true });
            notify('success','Reset Password link send to your email');
          }, 3000);
        } else {
          notify('error', response.data.message);
        }
      })
      .catch((err) => {
        setError('An error occurred while changing the password.');
        console.error(err); // Log error for debugging
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
    },
  });


  const notify = (status:any,data:any) => {

    if(status == 'error'){
          toast.error(data, {
            style: {
              borderRadius: '10px',
              // border: '1px solid #FFFAEE',
              padding: '16px',
              color: '#FFFAEE',
              background:"#057091",

            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#bf192a',
            },
          }
    );
    }else{
      toast.success(data, {
        style: {
          borderRadius: '10px',
          // border: '1px solid #FFFAEE',
          padding: '16px',
          color: '#ffc700 ',
          background:"#1C2241",

        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#ffc700 ',
        },
      }
      );
    }

  }
  return (
    <>
    <Toaster position="top-right"/>
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Change Password Here</h1>
        {/* end::Title */}

      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your phone</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder required text-gray-900 fs-6'>New Password</label>
        <input
          type='number'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder required text-gray-900 fs-6'>Confirm Password</label>
        <input
          type='number'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('current_password')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.current_password && formik.errors.current_password},
            {
              'is-valid': formik.touched.current_password && !formik.errors.current_password,
            }
          )}
        />
        {formik.touched.current_password && formik.errors.current_password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.current_password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
    </>
  )
}

