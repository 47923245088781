/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import React, {ChangeEvent, useEffect, useState} from 'react'
import { Edit } from '../../../../app/components/change_password/add'
import { Profile } from '../../../../app/components/profile/add'
import toast, { Toaster } from 'react-hot-toast';
const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [open, setOpen] = useState(false);
  const [open_model, setOpenModel] = useState('');
  const isOpenstatus = (status:any,data:any) => {
    setOpen(false);
    notify(status,data);
  }
  const notify = (status:any,data:any) => {
    if(status == 'error'){
          toast.error(data, {
            style: {
              borderRadius: '10px',
              // border: '1px solid #FFFAEE',
              padding: '16px',
              color: '#FFFAEE',
              background:"#057091",
              
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#bf192a',
            },
          }
    );
    }else{
      toast.success(data, {
        style: {
          borderRadius: '10px',
          // border: '1px solid #FFFAEE',
          padding: '16px',
          color: '#ffc700 ',
          background:"#1C2241",
          
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#ffc700 ',
        },
      }
      );
    }
    
  } 
  useEffect(() => {
  }, [currentUser]);
  return (
    <>
    <Toaster position="top-right"/>
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
          {currentUser?.pic ? ( <img alt='Logo' src={currentUser.pic } />):( <img alt='Logo' src={toAbsoluteUrl('/images/Team.jpg')} />)}
            {/* <img alt='Logo' src={toAbsoluteUrl('/images/staff.png')} /> */}
            
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {/* {currentUser?.first_name} {currentUser?.first_name} */}
              {currentUser?.first_name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}
      <div className="menu-item px-5">
          {/* <Link to='/profile' className="menu-link px-5">My Profile</Link> */}
          <a href='javascript:;'  onClick={()=>{setOpen(!open);setOpenModel('profile');}} className="menu-link px-5">My Profile</a>
        </div>
      <div className='menu-item px-5 my-1'>
        <a href='javascript:;'  onClick={()=>{setOpen(!open);setOpenModel('edit');}} className="menu-link px-5"> Change Password</a> </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
      {open ?(
        <div className= {open ? "modal fade show bg-black bg-opacity-50 d-block":"modal fade show bg-black bg-opacity-50 d-none"} id='kt_modal_add_company' tabIndex={-1} aria-hidden={true}>
                  
            {
              open_model == 'edit' ? (<>
                <div className='modal-dialog modal-m mt-5' >
                  <div className='modal-content' style={{backgroundColor: "#ffffff"}}>
                    <Edit isOpen={isOpenstatus} data={currentUser}/>
                  </div></div>
                </>):(<></>)
            }
            {
              open_model == 'profile' ? (<>
                {/* <div className='modal-dialog modal-xl m-auto mt-5' > */}
                <div className='modal-dialog modal-m mt-5' >
                  <div className='modal-content' style={{backgroundColor: "#ffffff"}}>
                    <Profile isOpen={isOpenstatus}  data={currentUser}/>
                  </div>
                </div>
                </>):(<></>)
            }
        </div>
      
        ):(
          <></>
        )
      }
    </>
  )
}

export {HeaderUserMenu}
