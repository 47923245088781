import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { Profile } from '../pages/profile/profile'
import { Dashboard } from '../pages/dashboard/index'
import { Course_view } from '../pages/dashboard/course_view/course_view'
import { Lesson_view } from '../pages/dashboard/lesson_view'

import { Wiselist } from '../pages/dashboard/wise_list/wise_list'
import { Eventlist } from '../pages/dashboard/events/even'
import { Toplist } from '../pages/dashboard/top-course/top'
import { Course } from '../pages/dashboard/course/index'
import {  ForgotPasswordChange } from '../modules/auth/components/password_change'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))


  return (
    <Routes>
      <Route element={<MasterLayout />}>
           {/* Redirect to Dashboard after success login/registartion */}
           <Route path='auth/*' element={<Navigate to='/Dashboard' />} />
        {/* Pages
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        {/* <Route path='old_members' element={<Old_Members />} /> */}
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='profile' element={<Profile/>} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='course_view' element={<Course_view />} />
        <Route path='lesson_view' element={<Lesson_view />} />
        <Route path='wise_list' element={<Wiselist />} />
        <Route path='even_list' element={<Eventlist />} />
        <Route path='top_course_list' element={<Toplist />} />
        <Route path='course/:id' element={<Course/>} />
        <Route path='password_change' element={<ForgotPasswordChange />} />
        {/* <Route path='edit_member' element={< Add_Member/>} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />


        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
